tbody td {
    padding: 0em;
    border-bottom: 0px solid rgb(223, 222, 222);
}

.accordion-button:not(.collapsed) {
    color: black;
    background-color: transparent;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
}
  
.accordion-button:focus {
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
}
