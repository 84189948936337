.first {
  color: #ffbf00
}

.second {
  color: #b5b7bb
}

.third {
  color: #cd7f32
}

span.award {
  margin-top: -0.7em;
  margin-bottom: -0.5em;
}
