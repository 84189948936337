body {
  font-family: 'open sans', sans-serif;
  font-size: 16px;
}

table.fixed { table-layout:fixed; }
table.fixed td { overflow: hidden; }

thead button {
  border: 0;
  border-radius: none;
  font-family: inherit;
  font-weight: 700;
  font-size: inherit;
  padding: 0em;
  margin-bottom: 1px;
  background-color: rgb(255, 255, 255);
}

thead button.ascending::after {
  content: '⬆︎';
  display: inline-block;
  margin-left: 0.3em;
}

thead button.descending::after {
  content: '⬇︎';
  display: inline-block;
  margin-left: 0.3em;
}

tbody td {
  padding: 0.5em;
  border-bottom: 1px solid #ccc;
}

tbody tr:hover {
  background-color: #eee;
}

.highest {
  font-weight: 650;
}

.achievement-icon {
  margin-left: 0.5em;
}

div.distance {
  --content: 6em;
}

div.elevation {
  --content: 5em;
}

div.longActivitiesCnt {
  --content: 2em;
}

div.longest {
  --content: 6em;
}

div.cellContainer {
  display: grid;
  grid-template-columns: 1fr var(--content) 1fr;
}
